/* eslint-disable no-alert */
import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { format } from 'date-fns'
import { Link, navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import qs from 'qs'

import useVerifyRole from '../../../hooks/verifyRole'
import Hero from '../../../components/Hero'
import Section from '../../../components/Section'
import SEO from '../../../components/SEO'
import { Input, Loader } from '../../../_shared/components'

const voucherStatusMap = {
  used: 'brukt',
  unused: 'ubrukt',
  revoked: 'trukket tilbake',
}

export default function Page() {
  const { loading, hasRole } = useVerifyRole(['admin'])

  // pull the code out of the url
  const { search } = useLocation()
  const { code = '' } = qs.parse(search, { ignoreQueryPrefix: true })

  const { loading: loadingData, data: { voucher } = {} } = useQuery(gql`
    query VoucherLookup($code: String!) {
      voucher(code: $code) {
        id
        months
        status
        created
        expires
        multiUse
        redemptions {
          count
          edges {
            node {
              id
              timestamp
              user {
                node {
                  id
                  email
                }
              }
            }
          }
        }
      }
    }
  `, {
    variables: { code },
    fetchPolicy: 'network-only',
  })

  function handleCodeChange(code) {
    // update url with the code typed in by the user
    navigate(`/admin/gavekort/sok?code=${encodeURIComponent(code)}`, { replace: true })
  }

  if (loading) {
    return <Loader />
  }

  if (!hasRole) {
    return null
  }

  return (
    <>
      <SEO title="Gavekort" />
      <Hero title={<>
        <Link to="/admin">Admin</Link>
        {` > `}<Link to="/admin/gavekort">Gavekort</Link>
        {` > `}Søk
      </>} type={['center', 'small']} />

      <Section size="small">
        <div style={{ minHeight: 'calc(100vh - 48.3rem)' }}>
          <p>Her kan du søke opp et gavekort og se informasjon om gavekortet og hvem som har brukt det.</p>
          <br/>
          <Input type="text" label="Gavekortkode" value={code} onChange={handleCodeChange} />

          {code && !loadingData && !voucher ? `Det finnes ikke noe gavekort med koden ${code}` : null}

          {voucher ? <div>
            <h2>
              {voucher.months} måneder gavekort
              {' – '}
              {voucher.multiUse ? 'flerbruksgavekort' : voucherStatusMap[voucher.status] || voucher.status}
            </h2>

            <span>Opprettet:{format(new Date(voucher.created), ' dd.MM.yyyy HH:mm')}</span>
            <br/>

            <span>
              Gyldig til:
              {format(new Date(voucher.expires), ' dd.MM.yyyy HH:mm')}
              {new Date(voucher.expires) < new Date() ? ' (utløpt)' : ''}
            </span>

            <ul style={{
              borderTop: '2px solid var(--color-highlighted)',
              marginTop: '1rem',
            }}>
              <li
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderBottom: '2px solid var(--color-highlighted)',
                  padding: '10px 0',
                }}
              >
                <span style={{ fontWeight: 'bold' }}>Bruker</span>
                <span style={{ fontWeight: 'bold' }}>Dato</span>
              </li>
              {voucher?.redemptions?.edges.map(({ node: redemption }) => (
                <li
                  key={redemption.id}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderBottom: '2px solid var(--color-highlighted)',
                    padding: '10px 0',
                  }}
                >
                  <span><Link to={`/admin/brukere?id=${redemption.user.node.id}`}>{redemption.user.node.email}</Link></span>
                  <span>{format(new Date(redemption.timestamp), 'dd.MM.yyyy HH:mm')}</span>
                </li>
              ))}
            </ul>
          </div> : null}
        </div>
      </Section>
    </>
  )
}
